<template>
  <v-container>

    <v-row>
      <v-col>
        <h2 class="mt-3 mb-3">Talleres</h2>
        <p class="mb-0">Nuestros talleres están disponibles para estudiantes de la Universidad Católica del Norte de Forma Gratuita, son recreativos, formativos, libres de asistencia y no son evaluados.</p>
      </v-col>
    </v-row>

    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card class="titleUnderline" min-height="12px" elevation="1" />
      </v-col>
    </v-row>

    <v-alert text dense class="text-body-2 mb-6">Para ver la información de un taller, presione la imagen de un taller</v-alert>

    <v-row v-if="!isLoading">

      <v-col v-if="courses.length > 0" cols="12">
        <v-row>
          <v-col v-for="card in courses" :key="card._id" lg="3" md="4" sm="6" xs="12">

            <v-card @click="goToCourse(card._id)" class="hoverExpand">
              <v-img
                :src="`${apiURL}/uploads/${card.imgURL[0]}`"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="250px"
              >
                <v-card-title v-text="card.name"></v-card-title>
                <v-card-subtitle>Ver taller</v-card-subtitle>
                <v-card-subtitle v-if="inRegistrationProcess">
                  <v-btn x-small>Inscríbete aqui</v-btn>
                </v-card-subtitle>

              </v-img>
            </v-card>

          </v-col>
        </v-row>
      </v-col>

      <v-col v-else sm="12">
        <v-alert type="info" elevation="5" class="my-5" >Lo sentimos, pero no hay cursos disponibles por el momento</v-alert>
      </v-col>

    </v-row>

    <LoadingIndicator v-else />
    
  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
  name: "Courses",
  components: { 
    LoadingIndicator 
  },
  computed: {
    apiURL() {
      return process.env.VUE_APP_APIURL
    },
  },
  data:() => {
    return {
      //state
      isLoading: false,
      //data
      inRegistrationProcess: false,
      courses: []
    }
  },
  methods: {
    async loadCourses() {
      try {

        this.isLoading = true

        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/course`)

        if(resp.data.status === 'success') {
          this.inRegistrationProcess = resp.data.data.inRegistrationProcess || false
          this.courses = (resp.data.data.courses.length > 0) ? resp.data.data.courses : []
        }

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    goToCourse(courseName) {
      this.$router.push(`/talleres/${courseName}`)
    }
  },
  mounted() {
    this.loadCourses()
  }
}
</script>

<style lang="scss" scoped>

.hoverExpand {
  transition: all .15s;
  background-color: white;

  & .v-image {
    transition: all .15s;
  }

  &:hover {
    transform: scale(1.015);
    cursor: pointer;

    & .v-image {
      filter: opacity(90%);
    }
  }
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}
</style>
